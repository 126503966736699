import {
  CustomDomainQueryFilterFields,
  FilterOperation,
  GetCustomDomainsListCsvDocument,
  TextDefaultQueryFilterFields,
  GetTextDefaultsListCsvDocument,
  CampaignQueryFilterFields,
  GetCampaignsListCsvDocument,
  ProviderColorQueryFilterFields,
  GetProviderColorListCsvDocument,
  GetUsersListCsvDocument,
  GetCollectionsListCsvDocument,
  AuditReportDocument,
  BillingReportDocument
} from "../generated/graphql";
import { BaseFilterTabsProps } from "../types";
import { PAGE_SIZE } from "../utils";

export const ORGANIZATIONS_PATH = "organization";

export const ORGANIZATION_MENU_ANCHOR_VIEW_LINKS = {
  OVERVIEW: "Overview",
  TEXT_DEFAULTS: "Text Defaults",
  PURCHASED_NUMBERS: "Purchased Numbers",
  DOMAINS: "Domains",
  UNREGISTERED_CAMPAIGNS: "Unregistered Campaigns",
  USERS: "Users",
  TEAMS: "Teams",
  AUDIT_REPORT: "Audit Report",
  LIST_FILTERS: "List Filters",
  PROVIDER_COLORS: "Provider Colors",
  BILLING_REPORT: "Billing Report",
  COLLECTIONS: "Collections",
  BALANCE: "Balance"
};

export const organizationTabFilters = ({
  id,
  selectedTab
}: BaseFilterTabsProps) => {
  const filterOptions = {
    [ORGANIZATION_MENU_ANCHOR_VIEW_LINKS.DOMAINS]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: CustomDomainQueryFilterFields.OrganizationId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      listDocument: GetCustomDomainsListCsvDocument
    },
    [ORGANIZATION_MENU_ANCHOR_VIEW_LINKS.TEXT_DEFAULTS]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: TextDefaultQueryFilterFields.OrganizationId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      listDocument: GetTextDefaultsListCsvDocument
    },
    [ORGANIZATION_MENU_ANCHOR_VIEW_LINKS.UNREGISTERED_CAMPAIGNS]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: CampaignQueryFilterFields.OrganizationId,
            operation: FilterOperation.With,
            value: "false"
          }
        ]
      },
      listDocument: GetCampaignsListCsvDocument
    },
    [ORGANIZATION_MENU_ANCHOR_VIEW_LINKS.PROVIDER_COLORS]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: ProviderColorQueryFilterFields.OrganizationId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      listDocument: GetProviderColorListCsvDocument
    },
    [ORGANIZATION_MENU_ANCHOR_VIEW_LINKS.USERS]: {
      variables: {
        first: PAGE_SIZE
      },
      listDocument: GetUsersListCsvDocument
    },
    [ORGANIZATION_MENU_ANCHOR_VIEW_LINKS.AUDIT_REPORT]: {
      listDocument: AuditReportDocument
    },
    [ORGANIZATION_MENU_ANCHOR_VIEW_LINKS.BILLING_REPORT]: {
      listDocument: BillingReportDocument
    },
    [ORGANIZATION_MENU_ANCHOR_VIEW_LINKS.COLLECTIONS]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: CustomDomainQueryFilterFields.OrganizationId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      listDocument: GetCollectionsListCsvDocument
    }
  };
  return filterOptions[selectedTab];
};
