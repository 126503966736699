import { ActionProps } from "@onehq/anton";
import { useNavigate, useParams } from "react-router-dom";
import { useGetBrandQuery } from "../../../generated/graphql";
import { BaseResource } from "../index";
import { QueryResult } from "@apollo/client";
import { COLLECTIONS_PATH } from "../../../constants/collectionConstants";
import {
  CAMPAIGNS_PATH,
  CLIENTS_PATH,
  PHONES_PATH,
  USERS_PATH
} from "../../../constants";

interface AddButtonProps {
  baseResource: BaseResource;
  variant: string;
}

interface Field {
  key: string;
  path: string;
}

interface Elements {
  getQuery?: Function;
  fields?: Field[];
  pathForAdd: string;
}

interface VariantElements {
  variant: string;
  elements?: Elements;
}

export const useAddButtonActions = ({
  baseResource,
  variant
}: AddButtonProps): ActionProps[] => {
  const params = useParams();
  const id = params.id || "";
  const navigateTo = useNavigate();

  const resourceVariantElements: Partial<
    Record<BaseResource, VariantElements[]>
  > = {
    Brand: [
      {
        variant: "Campaigns",
        elements: {
          getQuery: useGetBrandQuery,
          fields: [{ key: "clientId", path: "client.id" }],
          pathForAdd: `/${CAMPAIGNS_PATH}/new`
        }
      }
    ],
    Client: [
      {
        variant: "Purchased Numbers",
        elements: {
          // TODO: navigating (focus) to a specific section in a form is not working, check on Framework?
          pathForAdd: `/${CLIENTS_PATH}/${id}/edit/phone-numbers`
        }
      },
      {
        variant: "Contact Phones",
        elements: {
          pathForAdd: `/${PHONES_PATH}/new`
        }
      }
    ],
    Organization: [
      {
        variant: "Collections",
        elements: {
          pathForAdd: `/${COLLECTIONS_PATH}/new`
        }
      },
      {
        variant: "Users",
        elements: {
          pathForAdd: `/${USERS_PATH}/new`
        }
      }
    ]
  };

  const resourceArr = resourceVariantElements[baseResource];
  const resourceObj = resourceArr?.find(item => item.variant === variant);
  const { getQuery, fields } = resourceObj?.elements || {};

  const queryResult: QueryResult | undefined = getQuery?.({
    fetchPolicy: "cache-and-network",
    variables: { id }
  });
  const data = queryResult?.data;

  const pathForAdd = resourceObj?.elements?.pathForAdd;
  const finalPath = pathForAdd ?? "/";

  const onAddNew = () => {
    const stateValues: any = {
      [`${baseResource.toLowerCase()}Id`]: id
    };
    if (fields && data?.[baseResource.toLowerCase()]) {
      fields.forEach(field => {
        let value = data[baseResource.toLowerCase()];
        field.path.split(".").forEach(key => {
          value = value?.[key];
        });
        stateValues[field.key] = value;
      });
    }
    navigateTo(finalPath, { state: stateValues });
  };

  return [
    {
      type: "button",
      name: "Add",
      actionProps: {
        variant: "secondary",
        icon: "plus",
        iconPosition: "leading",
        onClick: () => void onAddNew()
      }
    }
  ];
};
