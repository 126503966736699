import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useReset } from "@onehq/anton";

const useDataFromResource = () => {
  const setValue = useReset();
  const location = useLocation();
  const state = location.state as {};

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (state) {
      Object.keys(state).forEach(key => {
        setValue(key, state[key]);
      });
    }
  }, [state]);
};

export default useDataFromResource;
