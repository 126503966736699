import { DotColor } from "@onehq/anton";
import { ProjectStatus } from "../generated/graphql";

export function projectStatusColor(status: ProjectStatus): DotColor {
  switch (status) {
    case ProjectStatus.Complete:
      return "royal50" as DotColor;
    case ProjectStatus.Live:
      return "pea60" as DotColor;
    case ProjectStatus.AwaitingLoad:
    case ProjectStatus.Loading:
    case ProjectStatus.LoadingWork:
      return "gold80" as DotColor;
    case ProjectStatus.Approved:
      return "iris50" as DotColor;
    case ProjectStatus.Submitted:
      return "iris80" as DotColor;
    case ProjectStatus.Error:
    case ProjectStatus.ErrorLoading:
    case ProjectStatus.ErrorLoadingWork:
      return "wine50" as DotColor;
    case ProjectStatus.Assigned:
      return "mint60" as DotColor;
    default:
      return "pea70" as DotColor;
  }
}

export function projectStatusBadgeColor(status: ProjectStatus): DotColor {
  switch (status) {
    case ProjectStatus.Setup:
    case ProjectStatus.Submitted:
      return "blue" as DotColor;
    case ProjectStatus.Approved:
    case ProjectStatus.Assigned:
      return "purple" as DotColor;
    case ProjectStatus.AwaitingLoad:
    case ProjectStatus.Loading:
      return "yellow" as DotColor;
    case ProjectStatus.LoadingWork:
      return "yellow" as DotColor;
    case ProjectStatus.AwaitingUnload:
    case ProjectStatus.Unloading:
      return "yellow" as DotColor;
    case ProjectStatus.Ready:
    case ProjectStatus.Queued:
    case ProjectStatus.Live:
    case ProjectStatus.Sending:
      return "green" as DotColor;
    case ProjectStatus.Cancelled:
    case ProjectStatus.Complete:
      return "blue" as DotColor;
    case ProjectStatus.Error:
    case ProjectStatus.ErrorLoading:
    case ProjectStatus.ErrorLoadingWork:
      return "red" as DotColor;
    default:
      return "blue" as DotColor;
  }
}
