/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { formatDollar } from "@onehq/helpers";
import { Table } from "@onehq/anton";
import { CollectionListFieldsFragment } from "../../generated/graphql";

import { BaseTableProps } from "../../types";
import { Link } from "@onehq/framework";
import { COLLECTIONS_PATH } from "../../constants/collectionConstants";
import { formatDate } from "../../utils";

type CollectionRow = CollectionListFieldsFragment & {
  bold?: boolean; // for the last row, that has a summary
};

export type CollectionTableProps = BaseTableProps<CollectionRow>;

export const CollectionTableColumns = [
  {
    id: "collectionType",
    header: "Type",
    accessorKey: "collectionType"
  },
  {
    id: "amount",
    header: "Amount",
    accessorKey: "amount",
    type: "number"
  },
  {
    id: "fee",
    header: "Fee",
    accessorKey: "fee",
    type: "number"
  },
  {
    id: "collectionDate",
    header: "Date",
    accessorKey: "collectionDate"
  }
];

export const collectionTableDataFormatter = (data?: Array<CollectionRow>) => [
  ...(data?.map((item: CollectionRow) => {
    const { id, collectionType, amount, fee, collectionDate, bold } = item;
    const type = bold ? formatter(collectionType, bold) : collectionType;
    const path = `/${COLLECTIONS_PATH}/${id}/overview`;
    const date = collectionDate ? formatDate(collectionDate) : "";

    return {
      collectionType: id ? <Link to={path}>{type}</Link> : type,
      amount: formatter(formatDollar(amount, 2), bold),
      fee: fee ? formatter(formatDollar(fee, 2), bold) : "-",
      collectionDate: date.toString().split(" ")[0]
    };
  }) || [])
];

const formatter = (value: string, bold?: boolean) => {
  if (bold) return <strong>{value}</strong>;
  else return value;
};

const CollectionTable = ({ data = [], ...props }: CollectionTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={CollectionTableColumns}
      data={collectionTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default CollectionTable;
