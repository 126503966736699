import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CheckboxField,
  Fieldset,
  TextField,
  ThemeProvider,
  Alert,
  FormBuilder,
  AlertTheme
} from "@onehq/anton";
import {
  StyledSpan,
  CheckboxLabel,
  Footer,
  FormContainer,
  FormWrapper,
  AlertWrapper,
  Header,
  ImageWrapper,
  PageWrapper,
  ResponsiveImage,
  SubmitButtonForm,
  FooterText,
  SubTitleHeader,
  CheckboxWrapper,
  CustomLink,
  Banner
} from "./OptInStyles"; // @ts-ignore
import RGO_ICON from "../../../assets/opts/icons/readygop.ico";
import { useGetClientBySlugQuery } from "../../../generated/graphql";

export const defautColor = "#A10C2B";

// @ts-ignore
const ResponsiveTextField = props => <TextField span={24} {...props} />;

const OptInPage = () => {
  const [alerts, setAlerts] = React.useState<String>();
  const [alertVariant, setAlertVariant] = React.useState<AlertTheme>("success");
  const navigateTo = useNavigate();

  const { slug } = useParams();

  const { data } = useGetClientBySlugQuery({
    variables: { slug: slug || "" },
    onCompleted: data => {
      const client = data?.clients?.nodes?.[0];
      if (!client) {
        navigateTo("/");
      }
    }
  });
  const currentPath = window.location.pathname;
  const client = data?.clients?.nodes?.[0];
  const clientName = client?.name;
  const color = client?.color || defautColor;
  const slogan = client?.slogan;
  const mediaUrl = client?.mediaUrl
    ? (process.env.REACT_APP_BACKEND_BASE_END_POINT || "") + client?.mediaUrl
    : "";

  const refForm = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const tabTitle = (clientName || "") + " - Opt In";
    let link: HTMLLinkElement | null =
      document.querySelector('link[rel="icon"]');
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = mediaUrl || RGO_ICON;
    document.title = tabTitle;
  }, [mediaUrl, clientName]);

  const onSubmit = () => {
    if (refForm && refForm.current) {
      refForm.current.submit();
    }
  };

  interface CheckboxWithLinkProps {
    checkboxName: string;
    checkboxLabel: React.ReactNode;
  }

  const optLabel = (
    <span>
      By submitting your phone number, you are authorizing {clientName} to send
      you text messages, notifications, and campaign updates and news. You agree
      to the{" "}
      <CustomLink color={color} href={currentPath + "/terms"}>
        terms
      </CustomLink>{" "}
      &{" "}
      <CustomLink color={color} href={currentPath + "/privacy"}>
        privacy policy
      </CustomLink>{" "}
      for messages from {clientName} to the phone number you provide. Message
      and data rates may apply. Msg frequency varies. Reply HELP for help. You
      can opt out at any time by replying STOP to any message sent from us.
    </span>
  );

  const CheckboxWithLink: React.FC<CheckboxWithLinkProps> = ({
    checkboxName,
    checkboxLabel
  }) => {
    return (
      <CheckboxLabel>
        <CheckboxField name={checkboxName} span={34} label="" />
        <StyledSpan color={color}>{checkboxLabel}</StyledSpan>
      </CheckboxLabel>
    );
  };

  const formComponent = (
    <FormBuilder
      ref={refForm}
      autosave={false}
      type="dialogForm"
      values={{
        firstname: "",
        lastname: "",
        email: "",
        phone: false,
        opt: false
      }}
      onSubmit={async values => {
        if (!values.opt || !values.phone) {
          setAlertVariant("error");
          setAlerts("Please check the box to proceed with the opt-in");
          setTimeout(() => {
            setAlerts("");
          }, 2000);
        } else {
          setAlertVariant("success");
          setAlerts("Thank you for opting in");
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
        }

        return Promise.resolve();
      }}
    >
      <Fieldset>
        <ResponsiveTextField label="First Name" name="firstname" />
        <ResponsiveTextField label="Last Name" name="lastname" />
        <ResponsiveTextField label="Email" name="email" />
        <ResponsiveTextField label="Phone" name="phone" required />
        <CheckboxWrapper>
          <CheckboxWithLink checkboxName="opt" checkboxLabel={optLabel} />
        </CheckboxWrapper>
        <SubmitButtonForm
          color={color}
          onClick={() => onSubmit()}
          data-testid={"button"}
        >
          SUBMIT
        </SubmitButtonForm>
      </Fieldset>
    </FormBuilder>
  );

  return (
    <ThemeProvider>
      <PageWrapper>
        <Header color={color}>Sign Up</Header>
        <Banner>
          <ImageWrapper>
            <ResponsiveImage src={mediaUrl || ""} />
          </ImageWrapper>
          <SubTitleHeader color={color}>{slogan}</SubTitleHeader>
        </Banner>
        <FormContainer>
          <FormWrapper>{formComponent}</FormWrapper>
        </FormContainer>
        <Footer color={color}>
          <FooterText>© 2024 All rights reserved.</FooterText>
          <FooterText>{client?.email}</FooterText>
        </Footer>
      </PageWrapper>
      {alerts && (
        <AlertWrapper>
          <Alert title={alerts} variant={alertVariant} />
        </AlertWrapper>
      )}
    </ThemeProvider>
  );
};

export default OptInPage;
