import React, { useCallback, useMemo, useState } from "react";
import { formatDollar } from "@onehq/helpers";
import {
  CampaignType,
  CollectionListFieldsFragment as Collection,
  CollectionQueryFilterFields,
  FilterOperation,
  useBillingReportQuery,
  useCollectionsSummaryQuery,
  useGetCollectionsListQuery
} from "../../generated/graphql";
import BalanceTable, {
  balanceTableDataFormatter as formatter
} from "./balanceReportTable";
import { Fieldset, TextInput } from "@onehq/anton";
import { round } from "lodash";
import CollectionTable from "../Collection/CollectionTable";
import DatesRangeForm, {
  DatesRangeFormSubmit as Submit
} from "../../pages/auditReport/datesRangeForm";

const { Registered, Unregistered } = CampaignType;

interface BalanceProps {
  id: string;
}

const Balance = ({ id }: BalanceProps) => {
  const [today] = useState(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  });

  const [startDate, setStartDate] = useState(startOfDay(today));
  const [endDate, setEndDate] = useState(endOfDay(today));

  // costs
  const { data: billingData, loading: loadingBilling } = useBillingReportQuery({
    fetchPolicy: "cache-and-network",
    variables: { startDate, endDate }
  });
  const rows = useMemo(() => {
    const nodes = billingData?.billingReport.nodes || [];
    return {
      registered: formatter(nodes, Registered, id),
      unregistered: formatter(nodes, Unregistered, id)
    };
  }, [id, billingData]);
  const cost = useMemo(() => {
    return round(
      Object.values(rows).reduce((total, type) => {
        const totalRow = type[3]; // totals
        if (totalRow) {
          const values: number[] = Object.values(totalRow);
          return total + values.reduce((sum, value) => sum + value, 0);
        } else return 0;
      }, 0) || 0,
      2
    );
  }, [rows]);

  // collections
  const { data: collectionData, loading: loadingCollestions } =
    useGetCollectionsListQuery({
      fetchPolicy: "cache-and-network",
      variables: {
        filters: [
          {
            field: CollectionQueryFilterFields.OrganizationId,
            operation: FilterOperation.Equal,
            value: id
          },
          {
            field: CollectionQueryFilterFields.CollectionDate,
            operation: FilterOperation.GreaterThan,
            value: startDate
          },
          {
            field: CollectionQueryFilterFields.CollectionDate,
            operation: FilterOperation.LessThan,
            value: endDate
          }
        ]
      }
    });
  const collections = useMemo(() => {
    return (collectionData?.collections.nodes || []) as Collection[];
  }, [collectionData]);

  // collections summary
  const { data: summaryData, loading: loadingSummary } =
    useCollectionsSummaryQuery({
      fetchPolicy: "cache-and-network",
      variables: { startDate, endDate }
    });
  const income = useMemo(() => {
    const amount = summaryData?.collectionsSummary.total_amount as string;
    return parseFloat(amount) || 0;
  }, [summaryData]);

  const onSubmit: Submit = useCallback(({ startDate, endDate }) => {
    setStartDate(startOfDay(startDate));
    setEndDate(endOfDay(endDate));
    return Promise.resolve();
  }, []);

  return (
    <>
      <DatesRangeForm defaultDate={today} onSubmit={onSubmit} />
      <Fieldset legend="Registered Campaigns" />
      <BalanceTable
        data={rows.registered}
        type={Registered}
        loading={loadingBilling}
      />
      <Fieldset legend="Unregistered Campaigns" />
      <BalanceTable
        data={rows.unregistered}
        type={Unregistered}
        loading={loadingBilling}
      />
      <Fieldset legend="Total Campaigns" />
      <TextInput value={formatDollar(cost)} disabled />
      <Fieldset legend="Payments" />
      <CollectionTable
        data={collections}
        loading={loadingCollestions || loadingSummary}
      />
      <Fieldset legend="Total Payments" />
      <TextInput value={formatDollar(income)} disabled />
      <Fieldset legend="Results" />
      <TextInput value={formatDollar(cost - income)} disabled />
    </>
  );
};

const startOfDay = (date: string) => {
  return `${date}T00:00:00`;
};

const endOfDay = (date: string) => {
  return `${date}T23:59:59`;
};

export default Balance;
