import React, { useMemo, useState } from "react";
import ReportForm from "../../auditReport/reportForm";
import {
  CollectionQueryFilterFields,
  FilterOperation,
  useCollectionsSummaryQuery
} from "../../../generated/graphql";

const normalize = (startDate: string, endDate: string) => {
  return {
    filters: [
      {
        field: CollectionQueryFilterFields.CollectionDate,
        operation: FilterOperation.GreaterThan,
        value: `${startDate}T00:00:00`
      },
      {
        field: CollectionQueryFilterFields.CollectionDate,
        operation: FilterOperation.LessThan,
        value: `${endDate}T23:59:59`
      }
    ]
  };
};

const CollectionReport = () => {
  const [today] = useState(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  });

  const { data: summaryData, refetch: refetchSummary } =
    useCollectionsSummaryQuery({
      fetchPolicy: "cache-and-network",
      variables: { startDate: today, endDate: today }
    });

  const summary = useMemo(() => {
    if (summaryData) {
      const summary = summaryData.collectionsSummary;
      const { total_amount: amount, total_fee: fee } = summary;
      return {
        collectionType: "Total",
        amount: parseFloat(amount as string),
        fee: parseFloat(fee as string),
        bold: true
      };
    } else return { collectionType: "Total", amount: 0, fee: 0, bold: true };
  }, [summaryData]);

  const onSubmit = (startDate: string, endDate: string) => {
    refetchSummary({ startDate, endDate }).catch(e => console.log(e));
  };

  return (
    <ReportForm
      variant="collections"
      normalize={normalize}
      onSubmit={onSubmit}
      summary={summary}
    />
  );
};

export default CollectionReport;
