import React from "react";
import { actionGroupFormat } from "@onehq/anton";
import { useIsMobile } from "@onehq/framework";
import { useAddButtonActions } from "./Add";
import { useExportButtonActions } from "./Export";

export type BaseResource =
  | "Brand"
  | "Campaign"
  | "Collection"
  | "Client"
  | "List"
  | "Organization"
  | "Phone"
  | "Project"
  | "Team"
  | "User";

interface ActionsButtonProps {
  baseResource: BaseResource;
  variant: string;
  showAddButton?: boolean;
  showExportButton?: boolean;
}

export const ActionsButton = ({
  baseResource,
  variant,
  showAddButton = false,
  showExportButton = false
}: ActionsButtonProps) => {
  const isMobile = useIsMobile();
  const addActions = useAddButtonActions({ baseResource, variant }) || [];
  const exportActions = useExportButtonActions({ baseResource, variant }) || [];

  const mergedActions = [
    ...(showExportButton ? exportActions : []),
    ...(showAddButton ? addActions : [])
  ];

  return <>{actionGroupFormat({ actions: mergedActions, mobile: isMobile })}</>;
};
