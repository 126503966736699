import { ActionProps } from "@onehq/anton";
import { useGenerateCsv } from "../../generateCsv";
import { useParams } from "react-router-dom";
import { BaseFilterTabsProps } from "../../../types";
import { useResourceTabsProvider } from "../../../providers/resource_tabs_provider";
import { TextQueryFilterInput } from "../../../generated/graphql";
import { useEffect, useMemo, useState } from "react";
import { PAGE_SIZE } from "../../../utils";
import { resourceImports } from "../../pages/ListPage";
import { BaseResource } from "../index";
import {
  campaignTabFilters,
  clientTabFilters,
  listTabFilters,
  organizationTabFilters,
  phoneTabFilters,
  projectTabFilters,
  teamTabFilters,
  userTabFilters
} from "../../../constants";

export const filterVariants = {
  Campaign: campaignTabFilters,
  Client: clientTabFilters,
  List: listTabFilters,
  Organization: organizationTabFilters,
  Phone: phoneTabFilters,
  Project: projectTabFilters,
  Team: teamTabFilters,
  User: userTabFilters
};

interface FilterVariantDefaults {
  tabFilters?: TextQueryFilterInput[];
  teamsInProject?: string[];
  selectedTextType?: string;
  projectsOnTeam?: string[];
  projectTexters?: string[];
}

type FilterVariantProps = BaseFilterTabsProps & FilterVariantDefaults;

type Filter = {
  [key: string]: any;
};

interface ExportButtonProps {
  baseResource: BaseResource;
  variant: string;
}

export const useExportButtonActions = ({
  baseResource,
  variant
}: ExportButtonProps): ActionProps[] => {
  const params = useParams();
  const id = params.id || "";
  const { value: resourceTabsContext } = useResourceTabsProvider();

  const [filterOptions, setFilterOptions] = useState<Filter>({});
  const [documentOptions, setDocumentOptions] = useState<any>();

  const {
    tabFilters,
    teamsInProject,
    selectedTextType,
    projectsOnTeam,
    projectTexters
  } = resourceTabsContext;

  const selectedFiltersProps = useMemo<FilterVariantProps>(() => {
    const props: FilterVariantProps = { id, selectedTab: variant };

    switch (baseResource) {
      case "Project":
        props.tabFilters = tabFilters;
        props.teamsInProject = teamsInProject;
        props.selectedTextType = selectedTextType;
        break;
      case "Team":
        props.projectsOnTeam = projectsOnTeam;
        break;
      case "User":
        props.projectTexters = projectTexters;
        break;
      default:
        break;
    }

    return props;
  }, [
    id,
    variant,
    baseResource,
    tabFilters,
    teamsInProject,
    selectedTextType,
    projectsOnTeam,
    projectTexters
  ]);

  const resourceImport = resourceImports[variant];
  const getListDocument = resourceImport
    ? resourceImport.getListDocument
    : null;
  const selectedFilters = useMemo(() => {
    const fn = filterVariants[baseResource];
    return fn ? (fn(selectedFiltersProps) as Filter) : null;
  }, [baseResource, selectedFiltersProps]);

  useEffect(() => {
    if (selectedFilters) {
      const variables = { ...resourceTabsContext };
      delete variables.selectedTextType;
      setDocumentOptions(selectedFilters.listDocument);
      setFilterOptions({
        ...(selectedFilters.variables as Filter),
        ...variables
      });
    } else {
      setDocumentOptions(getListDocument);
      setFilterOptions({ first: PAGE_SIZE });
    }
  }, [selectedFilters, resourceTabsContext, getListDocument]);

  const { csvButtonProps } = useGenerateCsv({
    apolloDocument: documentOptions,
    variables: filterOptions
  });

  return [
    {
      type: "button",
      name: "Export",
      actionProps: {
        ...csvButtonProps
      }
    }
  ];
};
