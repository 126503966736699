import React, { ComponentProps, useMemo } from "react";
import { SearchField } from "@onehq/anton";
import { GetCampaignQuery as Campaign } from "../../generated/graphql";
import { formatCampaignOption, formatClientList } from "../../utils";
import { Maybe } from "graphql/jsutils/Maybe";

const hasExpired = (endDate?: Maybe<string>) => {
  return endDate && new Date(endDate) < new Date();
};

const formatDisabledCampaignList = (campaigns: Campaign["campaign"][]) => {
  return campaigns.map(campaign => {
    const option = formatCampaignOption(campaign) as any;
    option.isDisabled = true;
    return option;
  });
};

export const campaignsToOptions = (
  campaigns: Campaign["campaign"][],
  disableExpired?: boolean,
  disableLimitReached?: boolean
) => {
  const expired = disableExpired
    ? campaigns.filter(campaign => {
        return hasExpired(campaign?.endDate as Maybe<string>);
      })
    : [];
  const limitReached = disableLimitReached
    ? campaigns.filter(campaign => !campaign?.canBuyMorePhones)
    : [];
  const active = campaigns.filter(campaign => {
    return (
      (!disableExpired || !hasExpired(campaign?.endDate as Maybe<string>)) &&
      (!disableLimitReached || campaign?.canBuyMorePhones)
    );
  });
  return [
    {
      label: "Active Campaigns",
      options: formatClientList(active)
    },
    {
      label: "Inactive Campaigns",
      options: formatDisabledCampaignList(expired)
    },
    {
      label: "Limit Reached Campaigns",
      options: formatDisabledCampaignList(limitReached)
    }
  ];
};

interface CampaignFieldProps
  extends Omit<ComponentProps<typeof SearchField>, "defaultOptions"> {
  campaigns?: Campaign["campaign"][];
  disableExpired?: boolean;
  disableLimitReached?: boolean;
}

const CampaignField = ({
  campaigns = [],
  disableExpired,
  disableLimitReached,
  ...props
}: CampaignFieldProps) => {
  const options = useMemo(() => {
    return campaignsToOptions(campaigns, disableExpired, disableLimitReached);
  }, [campaigns, disableExpired, disableLimitReached]);

  return <SearchField defaultOptions={options} {...props} />;
};

export default CampaignField;
