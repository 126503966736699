// react modules
import React from "react";
// third-party modules
import _ from "lodash";
import { NestedResourceRoute, ResourceRoute } from "@onehq/framework";
// app modules
import {
  BrandConfigurationInput,
  BrandFieldsFragment,
  useCreateBrandMutation,
  useGetBrandQuery,
  useNewBrandQuery,
  useUpdateBrandMutation
} from "../../generated/graphql";
import ListPage from "../../components/pages/ListPage";
import sections, {
  BrandGeneralFormOmitValues as formOmitValues
} from "./sections";
import DataOverview from "../../components/pages/DataOverview";
import BrandAnchor from "./BrandAnchor";
import {
  BRAND_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS,
  BRANDS_PATH
} from "../../constants";
import BrandShow from "./BrandShow";
import { ActionsButton } from "../../components/Actions";

// if something else changed that is not the brand configurations
function somethingElseChanged(brand: any) {
  const previous = brand.previous;
  if (brand.name !== previous.name) return true;
  if (brand.clientId !== previous.client?.id) return true;
  if (brand.provider !== previous.provider) return true;
  if (brand.throughput !== previous.throughput) return true;
  if (brand.code !== previous.code) return true;
  if (brand.color !== previous.color) return true;

  return false;
}

const BrandsRoute = new ResourceRoute({
  name: "Brand",
  path: BRANDS_PATH,
  AnchorComponent: BrandAnchor,
  IndexComponent: () => <ListPage variant="Brands" />,
  routes: [
    new NestedResourceRoute({
      name: "Overview",
      path: "overview",
      baseRoute: "Brand",
      IndexComponent: () => <DataOverview />
    }),
    new NestedResourceRoute({
      name: "Campaigns",
      path: "campaigns",
      baseRoute: "Brand",
      actions: (
        <ActionsButton
          baseResource={"Brand"}
          variant={VIEW_LINKS.CAMPAIGNS}
          showAddButton
        />
      ),
      IndexComponent: ({ id }: { id: string }) => (
        <BrandShow id={id} activeLinkId={VIEW_LINKS.CAMPAIGNS} />
      )
    })
  ],
  formProps: {
    autosave: true,
    scrollable: true,
    sections,
    // @ts-ignore
    hydrate: [useNewBrandQuery, "newBrand"],
    // @ts-ignore
    query: [useGetBrandQuery, "brand"],
    // @ts-ignore
    update: [useUpdateBrandMutation, "updateBrand"],
    // @ts-ignore
    create: [useCreateBrandMutation, "createBrand"],
    // @ts-ignore
    useInitialValues: (brand?: BrandFieldsFragment) => {
      // values for the brandConfigutationsTable fields (fields value, not table values)
      const brandConfigurationsAttributes =
        brand?.brandConfigurations?.map(b => {
          return {
            ...b,
            envKey: { label: b.envKey, value: b.envKey },
            destroy: false
          };
        }) || [];

      return {
        clientId: brand?.client
          ? { label: brand?.client.name, value: brand?.client.id }
          : undefined,
        brandConfigurationsAttributes,
        previous: brand
      };
    },
    // @ts-ignore
    normalize: (brand: BrandFieldsInput) => {
      if (!brand) return brand;

      const newAttr = { ...brand };

      // gets values from table fields and format these into an array data
      const brandConfigurationsAttributes =
        newAttr.brandConfigurationsAttributes;

      // every brand configuration but the last row (this is for create a new one) has to be complete, last one can be either empty or complete
      const hasValid = brandConfigurationsAttributes.every(bc => {
        return (
          bc.destroy || // if this row is about to be destroyed (deleted), pass it
          (bc.envKey && bc.envValue) || // if this row is not being deleted, has to be complete to pass
          (!bc.id &&
            ((bc.envKey && bc.envValue) || (!bc.envKey && !bc.envValue))) // creation rows (usually last row) can be completed or empty, but not half completed
        );
      });

      const brandChanged = somethingElseChanged(brand);
      if (!hasValid && !brandChanged) return { _omitSubmit: true };

      newAttr.brandConfigurations = brandConfigurationsAttributes
        ?.filter(
          config =>
            (config.envKey && config.envValue) || (config.id && config.destroy) // the config has to be completed or being destroyed
        )
        ?.map(configuration => {
          const { id, envKey, envValue, destroy } = configuration;

          return {
            id: id || null,
            envKey,
            envValue,
            _destroy: !!id && !!destroy
          };
        }) as BrandConfigurationInput[];

      return { ..._.omit(newAttr, formOmitValues) };
    }
  }
});

export { BrandsRoute };
