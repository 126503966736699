import { DateField, Fieldset, FormBuilder } from "@onehq/anton";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;

  & > form {
    margin: 0 -8px;
    padding: 0;
  }
`;

interface Values {
  startDate: string;
  endDate: string;
}

export type DatesRangeFormSubmit = (values: Values) => Promise<void>;

interface DatesRangeFormProps {
  defaultDate: string;
  onSubmit: DatesRangeFormSubmit;
}

const DatesRangeForm = ({ defaultDate, onSubmit }: DatesRangeFormProps) => {
  return (
    <Wrapper>
      <FormBuilder
        values={{ startDate: defaultDate, endDate: defaultDate }}
        onSubmit={onSubmit}
      >
        <Fieldset>
          <DateField label="Start Date" name="startDate" required />
          <DateField label="End Date" name="endDate" required />
        </Fieldset>
      </FormBuilder>
    </Wrapper>
  );
};

export default DatesRangeForm;
