/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { formatDollar } from "@onehq/helpers";
import { createColumnHelper } from "@tanstack/react-table";
import { Table, TableProps } from "@onehq/anton";
import { BaseTableProps } from "../../types";
import {
  BillingReport as Billing,
  CampaignType
} from "../../generated/graphql";
import { parseInt, round } from "lodash";

export interface BalanceRow {
  smsSent: number;
  mmsSent: number;
  smsReceived: number;
  mmsReceived: number;
}
type BalanceTableProps = BaseTableProps<BalanceRow | {}> & {
  type: CampaignType;
};
type Columns = TableProps["columns"];

const defaultValues: BalanceRow = {
  smsSent: 0,
  mmsSent: 0,
  smsReceived: 0,
  mmsReceived: 0
};

const helper = createColumnHelper<any>();
export const balanceTableColumns = (type: CampaignType): Columns => {
  return [
    helper.group({
      id: "type",
      header: type,
      columns: [
        helper.group({
          id: "total-sent",
          header: "Total Sent",
          columns: [
            helper.accessor("smsSent", { id: "smsSent", header: "SMS" }),
            helper.accessor("mmsSent", { id: "mmsSent", header: "MMS" })
          ]
        }),
        helper.group({
          id: "total-received",
          header: "Total Received",
          columns: [
            helper.accessor("smsReceived", {
              id: "smsReceived",
              header: "SMS"
            }),
            helper.accessor("mmsReceived", {
              id: "mmsReceived",
              header: "MMS"
            })
          ]
        })
      ]
    })
  ];
};

export const balanceTotals = (
  data: Array<Billing | null> = [],
  type: CampaignType = CampaignType.Registered,
  organizationId?: string
): BalanceRow => {
  const typeId = `CampaignType::::${type}`;
  const rows = data.filter(r => {
    return r?.campaignTypeId === typeId && r?.organizationId === organizationId;
  });

  const initialValues = { ...defaultValues };
  const balance = rows.reduce((totals, row) => {
    if (row?.textType === "SMS") {
      totals.smsReceived += parseInt(row?.received || "");
      totals.smsSent += parseInt(row?.sent || "");
    } else {
      totals.mmsReceived += parseInt(row?.received || "");
      totals.mmsSent += parseInt(row?.sent || "");
    }
    return totals;
  }, initialValues);

  return balance;
};

export const prices = (type: CampaignType) => {
  const price: BalanceRow = {
    smsSent: round(type === CampaignType.Registered ? 0.01 : 0.02, 3),
    mmsSent: round(type === CampaignType.Registered ? 0.035 : 0.036, 3),
    smsReceived: round(type === CampaignType.Registered ? 0.01 : 0.02, 3),
    mmsReceived: round(type === CampaignType.Registered ? 0.035 : 0.036, 3)
  };
  return price;
};

export const totalCosts = (balance: BalanceRow, price: BalanceRow) => {
  const costs = {
    smsSent: round(balance.smsSent * price.smsSent, 2),
    mmsSent: round(balance.mmsSent * price.mmsSent, 2),
    smsReceived: round(balance.smsReceived * price.smsReceived, 2),
    mmsReceived: round(balance.mmsReceived * price.mmsReceived, 2)
  };
  return costs;
};

// always 4 rows:
// 1. total messages sent/received
// 2. blank row
// 3. price per message sent/received
// 4. final price for every type of messages
export const balanceTableDataFormatter = (
  data: Array<Billing | null> = [],
  type: CampaignType = CampaignType.Registered,
  organizationId?: string
): (BalanceRow | {})[] => {
  const balance = balanceTotals(data, type, organizationId);
  const price = prices(type);
  const totals = totalCosts(balance, price);

  return [balance, {}, price, totals];
};

const formatNumberRows = (rows: (BalanceRow | {})[]) => {
  return rows.map((row, index) => {
    const final = Object.assign({}, row);
    const keys = Object.keys(final);
    if (keys.length === 0) return final;
    keys.forEach(key => {
      const value = final[key] as number;
      if (index === 0) {
        final[key] = value.toLocaleString("en-US", { useGrouping: true });
      } else if (index === 2) {
        final[key] = formatDollar(value, 3);
      } else {
        final[key] = formatDollar(value, 2);
      }
    });
    return final;
  });
};

const BalanceTable = ({ data = [], ...props }: BalanceTableProps) => {
  const { type } = props;
  return (
    <Table
      columns={balanceTableColumns(type)}
      data={formatNumberRows(data)}
      skeleton={props.loading}
      externalSort={props.externalSort}
    />
  );
};

export default BalanceTable;
