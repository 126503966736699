import { LinkSection, LinkType } from "@onehq/anton";

import { CustomCurrentUserQuery } from "./generated/graphql";
import { PrivateRoutes as Routes } from "./routes";

type CurrentUser = CustomCurrentUserQuery["currentUser"];

const defaultLinks = (): LinkSection => {
  let links: LinkType[] = [];

  links = links.concat([
    {
      id: Routes.Dashboard.path,
      name: "Dashboard",
      icon: "home"
    },
    {
      id: Routes.Projects.path,
      name: "Projects",
      icon: "folder"
    },
    {
      id: Routes.Clients.path,
      name: "Clients",
      icon: "briefcase"
    },
    {
      id: Routes.Brands.path,
      name: "Brands",
      icon: "shield"
    },
    {
      id: Routes.Lists.path,
      name: "Lists",
      icon: "list"
    },
    {
      id: Routes.ShortenedLinks.path,
      name: "Links",
      icon: "link"
    }
  ]);

  return { sectionTitle: "Ready GOP", sectionLinks: links };
};

const settingsLinks = (currentUser?: CurrentUser): LinkSection => {
  const links: LinkType[] = [];
  if (currentUser) {
    links.push(
      {
        id: `${Routes.Users.path}/${currentUser.id}/overview`,
        name: "Profile",
        icon: "userCheck"
      },
      {
        id: `${Routes.Organization.path}/${currentUser.currentOrganization?.id}/overview`,
        name: "Organization",
        icon: "settings"
      }
    );
  }
  // Only ReadyGOP org can see the Sidekiq Dashboard
  if (currentUser?.currentOrganization?.id === 1) {
    links.push({
      id: "/sidekiq",
      externalLocation: `${process.env.REACT_APP_BACKEND_BASE_END_POINT}/sidekiq`,
      name: "Sidekiq",
      icon: "externalLink",
      target: "_blank"
    });
  }

  links.push({ id: "/logout", name: "Logout", icon: "logOut" });

  return { sectionTitle: "Settings", sectionLinks: links };
};

const appNavLinks = (currentUser?: CurrentUser): LinkSection[] => [
  defaultLinks(),
  settingsLinks(currentUser)
];

export default appNavLinks;
