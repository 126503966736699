import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Carousel,
  CellDataType,
  Chart,
  ConfirmationModal,
  DotColor,
  Heading,
  HR,
  MenuAnchor,
  ResponsiveChart,
  Message as MsgFormat,
  MessagePreview,
  Table,
  IconNames,
  Badge
} from "@onehq/anton";
import {
  ADD,
  BaseResource,
  GrowlAlertDispatch,
  Link,
  REMOVE,
  REMOVE_ALL,
  ResourceRoute,
  useDispatchGrowlContext
} from "@onehq/framework";
import { useLazyQuery } from "@apollo/client";
import _, { round } from "lodash";
import { theme } from "@onehq/style";

import {
  LoadSendProjectDocument,
  LoadSendProjectOption,
  ProjectFieldsFragment,
  ProjectStatus,
  ProjectStatusGroup,
  ProjectTexter,
  refetchGetProjectQuery,
  ToggleFavoriteMutation,
  useDestroyProjectMutation,
  useGetProjectQuery,
  useGetProjectStatusOptionsQuery,
  useUpdateProjectStatusMutation,
  useToggleFavoriteMutation,
  useIsFavoriteQuery,
  useCustomCurrentUserQuery,
  TextStatusSummary,
  MediaPosition,
  useGetProjectStatusLazyQuery,
  DeliveryType,
  useUpdateProjectMutation,
  useGetGeneralTextStatusSummaryQuery,
  useGetTextsPerProviderSummaryQuery,
  useGetInvalidPhonesSummaryQuery,
  useGetOptOutPhonesSummaryQuery,
  useGetDuplicatePhonesSummaryQuery,
  useGetTotalTextsSummaryQuery
} from "../../../generated/graphql";
import { ActionItemPropsWithTestId } from "../../../types";
import { addSpacesBetweenWords } from "../../../utils/helperFunctions";
import { getOptionFieldsAndColor } from "../../../utils/options";
import { addErrorAlert, removeAlert } from "../../../utils/notifications";
import ProjectTestTexts from "./ProjectTestTexts";
import { LISTS_PATH, PROJECTS_PATH } from "../../../constants";
import sections from "../sections";
import { baseUrl } from "../../stepper/message";

const { spacing12 } = theme.space;

const StyledHeading = styled(Heading)`
  margin: ${spacing12} 0;
`;

const Shrinker = styled.div<{ rootHeight?: number }>`
  /* this will remove the unused part of the scaled children */
  height: ${props => `${(props.rootHeight || 0) * 0.76}px` || "unset"};
  /* root of phone preview */
  & > * {
    display: block;
    /* scale based on proportion of widths: anchor/phone */
    transform: scale(0.76);
    transform-origin: top;
    /* the phone wrapper - center it */
    & > :first-child {
      left: 50%;
      position: relative;
      transform: translateX(-50%);
    }
    /* blurred footer - center it */
    & > :last-child {
      left: 50%;
      transform: translateX(-50%);
      width: 328px;
    }
  }
`;

const COLORS: DotColor[] = [
  "wine50",
  "mint60",
  "royal50",
  "pea60",
  "gold60",
  "gold80",
  "iris50",
  "iris80"
];

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const projectStatusGroupOptions =
  _.orderBy(Object.keys(ProjectStatusGroup), undefined, "desc")
    // remove error from steps
    .filter(a => a !== ProjectStatusGroup.Error)
    .map((item, key) => ({
      id: key,
      label: item
    })) || [];

interface ProjectAnchorProps {
  id: string;
  route: ResourceRoute;
}

const ProjectAnchor = ({ id }: ProjectAnchorProps) => {
  // height of the phone preview, without the scale
  const [mediaHeight, setMediaHeight] = useState<number>();
  // true when the image in the phone is fully loaded, help calculate the preview height
  const [loadedImage, setLoadedImage] = useState<boolean>(false);
  const [projectStatus, setProjectStatus] = useState<ProjectStatus>();
  const [currentTimer, setCurrentTimer] = useState<NodeJS.Timer | undefined>();
  // alert
  const alert: GrowlAlertDispatch = useDispatchGrowlContext();
  const [idForDeleteAlert] = useState(Math.floor(Math.random() * 10000 + 1));

  // navigation
  const navigateTo = useNavigate();

  // current user
  const { data: currentUser } = useCustomCurrentUserQuery();

  // modal
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [showTestTexts, toggleShowTestTexts] = useState(false);

  // fetch data
  const { data: statusOptionsData, loading: statusOptionsloading } =
    useGetProjectStatusOptionsQuery();
  const { data: projectData, loading: projectLoading } = useGetProjectQuery({
    fetchPolicy: "cache-and-network",
    context: {
      headers: {
        isView: "true",
        resource: "Project"
      }
    },
    variables: { id }
  });
  const project = useMemo(() => {
    return { ...projectData?.project } as ProjectFieldsFragment | undefined;
  }, [projectData]);

  const { data: totalTextsSummary } = useGetTotalTextsSummaryQuery({
    fetchPolicy: "cache-and-network",
    variables: { projectId: id }
  });

  const { data: generalTextStatusSummary } =
    useGetGeneralTextStatusSummaryQuery({
      fetchPolicy: "cache-and-network",
      variables: { projectId: id }
    });

  const { data: textsPerProviderSummary } = useGetTextsPerProviderSummaryQuery({
    fetchPolicy: "cache-and-network",
    variables: { projectId: id }
  });

  const { data: invalidPhonesSummary } = useGetInvalidPhonesSummaryQuery({
    fetchPolicy: "cache-and-network",
    variables: { projectId: id }
  });

  const { data: duplicatePhonesSummary } = useGetDuplicatePhonesSummaryQuery({
    fetchPolicy: "cache-and-network",
    variables: { projectId: id }
  });

  const { data: optOutPhonesSummary } = useGetOptOutPhonesSummaryQuery({
    fetchPolicy: "cache-and-network",
    variables: { projectId: id }
  });

  // check and load project status - start
  const [getProjectStatusQuery] = useGetProjectStatusLazyQuery({
    fetchPolicy: "cache-and-network"
  });
  const fetchProjectStatus = useMemo(() => {
    return () => {
      setCurrentTimer(undefined);
      getProjectStatusQuery({ variables: { id } }).then(
        response => {
          const newStatus = response.data?.project?.projectStatus;
          const statusGroup = response.data?.project?.projectStatusGroup;
          setProjectStatus(newStatus);
          if (statusGroup === ProjectStatusGroup.Loading) {
            setCurrentTimer(currentTimer => {
              if (currentTimer)
                clearInterval(currentTimer as unknown as number);
              return setInterval(fetchProjectStatus, 5000);
            });
          }
        },
        error => console.log(error)
      );
    };
  }, [id, getProjectStatusQuery]);
  useEffect(() => {
    if (!projectStatus) fetchProjectStatus();
  }, [projectStatus, fetchProjectStatus]);
  useEffect(() => {
    // clean up when unmound compounent
    return () => clearInterval(currentTimer as unknown as number);
  }, [currentTimer]);
  // check and load project status - end

  const projectStatusOptions = useMemo(() => {
    return getOptionFieldsAndColor(statusOptionsData, COLORS);
  }, [statusOptionsData]);

  const [updateProjectStatus] = useUpdateProjectStatusMutation({
    refetchQueries: [refetchGetProjectQuery({ id })]
  });
  const changeStatus = useMemo(() => {
    return async (
      currentProject: ProjectFieldsFragment | undefined,
      newStatus: ProjectStatus
    ) => {
      if (!currentProject) return;
      const newProject = {
        id: currentProject.id,
        projectType: currentProject.projectType,
        projectStatus: newStatus
      };
      await updateProjectStatus({
        variables: { id: newProject.id, attributes: newProject },
        onCompleted: response => {
          const errors = response?.updateProject?.errors || {};
          const errorKeys = Object.keys(errors);
          if (errorKeys.length === 0) {
            alert({
              type: ADD,
              payload: {
                title: "Project Status",
                message: "The status has been changed",
                variant: "success"
              }
            });
            fetchProjectStatus();
          } else {
            const message = errorKeys.map(e => `${e}: ${errors[e]}`).join(", ");
            addErrorAlert(alert, "Project Status Error", message);
          }
        },
        onError: err => {
          console.error(err); // the error if that is the case
        }
      });
    };
  }, [updateProjectStatus, fetchProjectStatus, alert]);

  const [updateProject] = useUpdateProjectMutation();
  const togglePause = useMemo(() => {
    return async (
      currentProject: ProjectFieldsFragment,
      isPaused: boolean | null
    ) => {
      if (!currentProject) return;
      const newProject = {
        id: currentProject.id,
        isPaused
      };
      await updateProject({
        variables: { id: newProject.id, attributes: newProject },
        onCompleted: response => {
          if (Object.keys(response?.updateProject?.errors || {}).length === 0) {
            alert({
              type: ADD,
              payload: {
                title: "Project Status",
                message: `The project is being ${
                  isPaused ? "paused" : "resumed"
                }`,
                variant: "success"
              }
            });
          }
        },
        onError: err => {
          console.error(err);
        }
      });
    };
  }, [updateProject, alert]);

  const { data: isFavoriteData } = useIsFavoriteQuery({
    fetchPolicy: "no-cache",
    variables: { projectId: id }
  });

  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  useEffect(() => {
    setIsFavorite(isFavoriteData?.isFavorite || false);
  }, [isFavoriteData]);

  const [toggleFavorite] = useToggleFavoriteMutation({
    onCompleted: (response: ToggleFavoriteMutation) => {
      setIsFavorite(response.toggleFavorite?.isFavorite || false);
    }
  });

  // inicially, this was meant to be an useRef,
  // but isn't recommended to add it in a dependency list
  // so instead we are using a useCallback instead.
  // the dependency list is in order to check updates in the height of the preview
  const handleRect = useCallback(
    node => {
      const height: number | undefined = node?.clientHeight;
      setMediaHeight(height);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadedImage, project?.mediaUrl, project?.message]
  );
  // if we remove/change the image, reset the loading image
  useEffect(() => {
    setLoadedImage(false);
  }, [project?.mediaUrl]);

  // load/send project action
  const [runQuery] = useLazyQuery(LoadSendProjectDocument);
  const loadSendProject = useMemo(() => {
    return (
      option: string,
      phoneIds: string[] = [],
      body = "",
      fromPhoneId?: string
    ) => {
      const idForAlert = Math.floor(Math.random() * 10000 + 1);
      alert({
        type: REMOVE_ALL,
        payload: {
          id: idForAlert,
          title: "",
          variant: "progress"
        }
      });
      alert({
        type: ADD,
        payload: {
          id: idForAlert,
          title:
            option === LoadSendProjectOption.LoadProject
              ? "Loading Project"
              : "Sending Texts",
          variant: "progress",
          indeterminate: true
        }
      });
      let variables = {};
      if (option === LoadSendProjectOption.TestText) {
        variables = { projectId: id, option, phoneIds, body, fromPhoneId };
      } else {
        variables = { projectId: id, option };
      }
      void runQuery({
        fetchPolicy: "no-cache",
        variables,
        onCompleted: response => {
          removeAlert(alert, idForAlert);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          if (response.loadSendProject?.errors?.length > 0) {
            response.loadSendProject?.errors &&
              addErrorAlert(
                alert,
                "Error, project not loaded",
                // eslint-disable-next-line
                Object.values(response.loadSendProject.errors).join(". ")
              );
          } else {
            alert({
              type: REMOVE_ALL,
              payload: {
                id: idForAlert,
                title: "",
                variant: "success"
              }
            });
            alert({
              type: ADD,
              payload: {
                title: "All changes saved",
                message: response.loadSendProject.message,
                variant: "success"
              }
            });
            fetchProjectStatus();
          }
        },
        onError: err => {
          alert({
            type: REMOVE_ALL,
            payload: {
              id: idForAlert,
              title: "",
              variant: "success"
            }
          });
          addErrorAlert(alert, "Error, project not loaded", err.message);
          console.error(err.message); // the error if that is the case
        }
      });
    };
  }, [id, alert, fetchProjectStatus, runQuery]);

  // destroy project action
  const [destroyProjectMutation] = useDestroyProjectMutation({
    onCompleted: response => {
      alert({
        type: REMOVE,
        payload: {
          id: idForDeleteAlert,
          title: "",
          variant: "success"
        }
      });
      if (Object.keys(response?.destroyProject?.errors || {}).length === 0) {
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            message: "The project has been deleted successfully",
            variant: "success"
          }
        });
        navigateTo(`/${PROJECTS_PATH}`);
      }
    },
    onError: err => {
      alert({
        type: REMOVE,
        payload: {
          id: idForDeleteAlert,
          title: "",
          variant: "success"
        }
      });
      console.error(err); // the error if that is the case
    }
  });
  const deleteProject = async () => {
    alert({
      type: ADD,
      payload: {
        id: idForDeleteAlert,
        title: "Deleting Project",
        variant: "progress",
        indeterminate: true
      }
    });
    await destroyProjectMutation({ variables: { id } });
  };

  // actions
  const kebabMenuItems = useMemo(() => {
    const kebabMenuItems = [] as ActionItemPropsWithTestId[];
    const addKebabOptions = (name: string, option: string) => {
      kebabMenuItems.unshift({
        name,
        icon: "loader",
        "data-testid": "action-loadProject",
        onClick: () => loadSendProject(option)
      });
    };

    // toggle show test texts action
    kebabMenuItems.unshift({
      name: "Test Texts",
      icon: "send",
      "data-testid": "action-testTexts",
      onClick: () => {
        toggleShowTestTexts(true);
      }
    });

    // resend texts with errors if project Completed
    if (
      project?.projectStatus &&
      [
        ProjectStatus.Live,
        ProjectStatus.Sending,
        ProjectStatus.Complete
      ].includes(project.projectStatus)
    ) {
      addKebabOptions("Resend", LoadSendProjectOption.ResendTexts);
    }

    // add load/send project to actions
    if (
      currentUser?.currentUser.email === "kyle.g@readygop.com" &&
      project?.projectStatus &&
      [ProjectStatus.Live, ProjectStatus.Sending].includes(
        project.projectStatus
      ) &&
      DeliveryType.Blast.toString() !== project?.deliveryType
    )
      kebabMenuItems.unshift({
        name: "Blast",
        icon: "send",
        "data-testid": "action-sendTexts",
        onClick: () => loadSendProject(LoadSendProjectOption.SendTexts)
      });

    if (
      ProjectStatus.Sending.toString() === project?.projectStatus &&
      DeliveryType.Blast.toString() === project.deliveryType &&
      !project?.isPaused
    )
      kebabMenuItems.unshift({
        name: "Pause",
        icon: "pause",
        "data-testid": "action-sendTexts",
        onClick: () => void togglePause(project, true)
      });

    if (
      ProjectStatus.Sending.toString() === project?.projectStatus &&
      DeliveryType.Blast.toString() === project.deliveryType &&
      project?.isPaused
    )
      kebabMenuItems.unshift({
        name: "Resume",
        icon: "play",
        "data-testid": "action-sendTexts",
        onClick: () => void togglePause(project, null)
      });

    // load/unload project action (based on current project status)
    if (ProjectStatus.Assigned.toString() === project?.projectStatus) {
      addKebabOptions("Load Project", LoadSendProjectOption.LoadProject);
    } else if (
      ProjectStatus.Live.toString() === project?.projectStatus ||
      ProjectStatus.Ready.toString() === project?.projectStatus ||
      ProjectStatus.Queued.toString() === project?.projectStatus ||
      ProjectStatusGroup.Error.toString() === project?.projectStatusGroup
    ) {
      addKebabOptions("Unload Project", LoadSendProjectOption.UnloadProject);
    }

    // show delete modal action
    kebabMenuItems.push({
      name: "Delete",
      icon: "trash2",
      color: "wine50",
      onClick: () => handleShow()
    });

    // change status action
    if (project?.projectStatus === ProjectStatus.Submitted) {
      kebabMenuItems.unshift({
        name: "Project Approved",
        icon: "arrowRightCircle",
        onClick: () => void changeStatus(project, ProjectStatus.Approved)
      });
    }
    if (project?.projectStatus === ProjectStatus.Approved) {
      kebabMenuItems.unshift({
        name: "Project Assigned",
        icon: "arrowRightCircle",
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick: () => changeStatus(project, ProjectStatus.Assigned)
      });
    }

    const status = project?.projectStatus;
    if (
      currentUser?.currentUser.email === "kyle.g@readygop.com" &&
      status &&
      [ProjectStatus.Ready, ProjectStatus.Queued].includes(status)
    ) {
      kebabMenuItems.unshift({
        name: "Project Live",
        icon: "arrowRightCircle",
        onClick: () => void changeStatus(project, ProjectStatus.Live)
      });
    }
    return kebabMenuItems;
  }, [project, currentUser, changeStatus, loadSendProject, togglePause]);

  // cards
  const cards = useMemo(() => {
    const ProjectPauseBadge = () => (
      <Card.Badges>
        <Badge color={"yellow"}>Project is paused</Badge>
      </Card.Badges>
    );

    const badgesCard = (
      <Card.Badges>
        <ProjectPauseBadge />
      </Card.Badges>
    );

    const infoCard = (
      <Card.BasicInfo
        data={[
          {
            label: "Client",
            value: project?.client ? (
              <Link to={`/clients/${project.client.id}`}>
                {project.client.name}
              </Link>
            ) : (
              "--"
            )
          },
          {
            label: "List",
            value: project?.list ? (
              <Link to={`/${LISTS_PATH}/${project.list.id}/overview`}>
                {project.list.name}
              </Link>
            ) : (
              "--"
            )
          },
          ...(project?.mediaSize
            ? [
                {
                  label: "Media Size",
                  value: project.mediaSize
                }
              ]
            : [])
        ]}
      />
    );

    let cards = project?.isPaused ? [badgesCard, infoCard] : [infoCard];

    // texters card
    const hasProjectTexter = project?.projectTexters?.length;
    if (hasProjectTexter) {
      const infoCardTexters = (
        <Card.Flex>
          <StyledHeading type="icon">Texters</StyledHeading>
          <Table
            variant="minimal"
            disableSort
            columns={[
              {
                header: "Name",
                accessorKey: "name"
              },
              {
                header: "Quota",
                dataType: "number" as CellDataType,
                accessorKey: "quota"
              }
            ]}
            data={[
              ...project.projectTexters.map((v: ProjectTexter) => ({
                quota: v.quota,
                name: <Link to={`/users/${v.userId}`}>{v.user.name}</Link>
              }))
            ]}
          />
        </Card.Flex>
      );
      cards = [...cards, infoCardTexters];
    }

    // media card
    if (project?.message || project?.mediaUrl) {
      const text = project.message;
      const mediaURL = baseUrl + project.mediaUrl;
      const mediaFileType = project.mediaFiletype;

      let carouselJSX: ReactElement = <></>;

      if (mediaFileType?.includes("audio")) {
        carouselJSX = (
          <audio style={{ width: "inherit", minHeight: 100 }} controls>
            <source src={mediaURL} />
          </audio>
        );
      } else if (mediaFileType?.includes("video")) {
        carouselJSX = (
          <video style={{ width: "inherit", minHeight: 100 }} controls muted>
            <source src={mediaURL} />
          </video>
        );
      } else if (mediaFileType?.includes("image") || text) {
        const mediaUrl = project.mediaUrl;
        const mediaPosition = project.mediaPosition;

        const content: MsgFormat[] = [
          { format: "text", text: "Hey 👋", type: "received" }
        ];

        if (text) {
          content.push({ format: "text", text: text || "", type: "sent" });
        }
        if (mediaUrl) {
          const img: MsgFormat = {
            format: "attachment",
            mediaURL: baseUrl + mediaUrl,
            alt: "attachment",
            type: "sent"
          };
          const mediaBefore = mediaPosition === MediaPosition.Before;
          content.splice(mediaBefore ? 1 : text ? 2 : 1, 0, img);
        }
        carouselJSX = (
          <MessagePreview
            content={content}
            type="sms"
            onImageLoad={() => setLoadedImage(true)}
            ref={handleRect}
          />
        );
      }
      const mediaCard = (
        <>
          {mediaFileType?.includes("image") || text ? (
            <Shrinker rootHeight={mediaHeight}>{carouselJSX}</Shrinker>
          ) : (
            <Carousel
              style={{ paddingTop: 5, paddingBottom: 5 }}
              key="1"
              pagination
              navigation
              items={[carouselJSX]}
            />
          )}
        </>
      );
      cards = [...cards, mediaCard];
    }

    // list summary card
    const infoCardPhones = (
      <Card.Flex>
        <StyledHeading>List</StyledHeading>
        <Card.BasicInfo
          data={[
            {
              value: "List",
              label: totalTextsSummary?.totalTextsSummary?.totalListTexts
            },
            {
              value: "Removed",
              label:
                (totalTextsSummary?.totalTextsSummary?.totalListTexts || 0) -
                (totalTextsSummary?.totalTextsSummary?.totalExpectedTexts || 0)
            }
          ]}
        />
        <HR />
        <Card.BasicInfo
          data={[
            {
              value: "Project Total",
              label: totalTextsSummary?.totalTextsSummary?.totalProjectTexts
            }
          ]}
        />
        <HR />
        <Card.BasicInfo
          data={[
            {
              value: (
                <Link to={`/projects/${id}/duplicate_phones`}>
                  Duplicate Phones
                </Link>
              ),
              label:
                duplicatePhonesSummary?.duplicatePhonesSummary
                  ?.totalDuplicatePhones
            },
            {
              value: (
                <Link to={`/projects/${id}/invalid_phones`}>
                  Invalid Phones
                </Link>
              ),
              label:
                invalidPhonesSummary?.invalidPhonesSummary?.totalInvalidPhones
            },
            {
              value: (
                <Link to={`/projects/${id}/opt_out_phones`}>Opt Outs</Link>
              ),
              label: optOutPhonesSummary?.optOutPhonesSummary?.totalOptOutPhones
            }
          ]}
        />
      </Card.Flex>
    );

    // repeated texts
    const infoCardRepeatedTexts = (
      <Card.Flex>
        <StyledHeading>Repeated Texts</StyledHeading>
        <Card.BasicInfo
          data={[
            {
              value: "Repeated Texts",
              label: project?.repeatedTextsCount || 0
            }
          ]}
        />
      </Card.Flex>
    );

    // text summary cards
    const readySendingTextSummary =
      generalTextStatusSummary?.generalTextStatusSummary?.ready;
    const readySendingtextTotal =
      generalTextStatusSummary?.generalTextStatusSummary?.totalReady;
    const sentTextSummary =
      generalTextStatusSummary?.generalTextStatusSummary?.sent;
    const repliedTextSummary =
      generalTextStatusSummary?.generalTextStatusSummary?.replied || [];
    const totalSentTexts =
      generalTextStatusSummary?.generalTextStatusSummary?.totalSent || 0;
    const totalErrorTexts =
      generalTextStatusSummary?.generalTextStatusSummary?.totalError || 0;
    const totalTestTexts =
      generalTextStatusSummary?.generalTextStatusSummary?.sent?.filter(
        e => e.status === "Test"
      )?.[0]?.number || 0;
    const totalSeedTexts =
      generalTextStatusSummary?.generalTextStatusSummary?.sent?.filter(
        e => e.status === "Seed"
      )?.[0]?.number || 0;
    const totalTexts =
      totalTextsSummary?.totalTextsSummary?.totalProjectTexts || 1;
    const totalNoTestTexts =
      totalSentTexts - totalTestTexts - totalSeedTexts + totalErrorTexts;

    // total texts per provider card
    const textsPerProvider =
      textsPerProviderSummary?.textsPerProviderSummary?.totalPerProvider || [];
    const totalProviderTexts = textsPerProvider.reduce(
      (sum, v) => sum + v.number,
      0
    );

    const chartData = [
      {
        name: "Successful",
        value: round(
          ((totalSentTexts - totalTestTexts - totalSeedTexts) / totalTexts) *
            100,
          2
        )
      },
      {
        name: "Error",
        value: round((totalErrorTexts / totalTexts) * 100, 2)
      }
    ];

    const infoCardTextPerProvider = (
      <Card.Flex>
        <StyledHeading>Texts Per Provider</StyledHeading>
        <Table
          variant="minimal"
          disableSort
          hideHeader
          columns={[
            {
              header: "Status",
              accessorKey: "status",
              footer: "Total"
            },
            {
              header: "Quantity",
              dataType: "number" as CellDataType,
              accessorKey: "number",
              footer: totalProviderTexts?.toString()
            },
            {
              header: "Percentage",
              dataType: "number" as CellDataType,
              accessorKey: "percentage"
            }
          ]}
          data={[
            ...(textsPerProvider
              ? textsPerProvider.map((v: TextStatusSummary) => ({
                  status: v.status,
                  number: v.number,
                  percentage: `${round((v.number / totalTexts) * 100, 2)}%`
                }))
              : [])
          ]}
        />
        <Card.Flex // Displays only if the project has sent texts
          style={{
            display: totalSentTexts || totalErrorTexts ? "block" : "none"
          }}
        >
          <ResponsiveChart width={200}>
            <Chart
              data={chartData}
              color={true}
              type={"Pie"}
              formatter={v => `${v}%`}
            />
          </ResponsiveChart>
        </Card.Flex>
      </Card.Flex>
    );

    // ready text summary card
    const infoCardTextReadySendSummary = (
      <Card.Flex>
        <StyledHeading>Ready Texts</StyledHeading>
        <Table
          variant="minimal"
          disableSort
          hideHeader
          columns={[
            {
              header: "Status",
              accessorKey: "status",
              footer: "Total"
            },
            {
              header: "Quantity",
              dataType: "number" as CellDataType,
              accessorKey: "number",
              footer: readySendingtextTotal?.toString()
            },
            {
              header: "Percentage",
              dataType: "number" as CellDataType,
              accessorKey: "percentage"
            }
          ]}
          data={[
            ...(readySendingTextSummary
              ? readySendingTextSummary.map((v: TextStatusSummary) => ({
                  status: (
                    <Link
                      to={`/projects/${id}/texts`}
                      state={{ textType: v.status }}
                    >
                      {v.status}
                    </Link>
                  ),
                  number: v.number,
                  percentage: `${round((v.number / totalTexts) * 100, 2)}%`
                }))
              : [])
          ]}
        />
      </Card.Flex>
    );

    // overall text summary card
    const infoCardOverallTextSummary = (
      <Card.Flex>
        <StyledHeading>Total Project Texts</StyledHeading>
        <Table
          variant="minimal"
          disableSort
          hideHeader
          columns={[
            {
              header: "Totals",
              accessorKey: "totals",
              footer: "Totals"
            },
            {
              header: "Quantity",
              dataType: "number" as CellDataType,
              accessorKey: "number",
              footer: totalNoTestTexts.toString()
            },
            {
              header: "Percentage",
              dataType: "number" as CellDataType,
              accessorKey: "percentage"
            }
          ]}
          data={
            totalSentTexts - totalTestTexts - totalSeedTexts || totalErrorTexts
              ? [
                  {
                    totals: "Successful",
                    number: (
                      totalSentTexts -
                      totalTestTexts -
                      totalSeedTexts
                    )?.toString(),
                    percentage: `${round(
                      ((totalSentTexts - totalTestTexts - totalSeedTexts) /
                        totalTexts) *
                        100,
                      2
                    )}%`
                  },
                  {
                    totals: "Error",
                    number: totalErrorTexts?.toString(),
                    percentage: `${round(
                      (totalErrorTexts / totalTexts) * 100,
                      2
                    )}%`
                  }
                ]
              : []
          }
        />
        <Card.Flex // Displays only if the project has sent texts
          style={{
            marginTop: "2rem",
            display:
              sentTextSummary?.length === 0 && repliedTextSummary.length === 0
                ? "none"
                : "grid"
          }}
        >
          <Table
            variant="minimal"
            disableSort
            hideHeader
            columns={[
              {
                header: "Status",
                accessorKey: "status"
              },
              {
                header: "Quantity",
                dataType: "number" as CellDataType,
                accessorKey: "number"
              },
              {
                header: "Percentage",
                dataType: "number" as CellDataType,
                accessorKey: "percentage",
                minSize: 275
              }
            ]}
            data={[
              ...repliedTextSummary.map((v: TextStatusSummary) => ({
                status: (
                  <Link
                    to={`/projects/${id}/texts`}
                    state={{ textType: v.status }}
                  >
                    {v.status}
                  </Link>
                ),
                number: v.number,
                percentage: `${round(
                  (v.number / totalTexts) * 100,
                  2
                ).toString()}%`
              }))
            ]}
          />
        </Card.Flex>
      </Card.Flex>
    );

    // sent text summary card
    const infoCardTextSentSummary = (
      <Card.Flex>
        <Card.Flex>
          <StyledHeading>Sent Texts</StyledHeading>
          <Table
            variant="minimal"
            disableSort
            hideHeader
            columns={[
              {
                header: "Status",
                accessorKey: "status",
                footer: "Total"
              },
              {
                header: "Quantity",
                dataType: "number" as CellDataType,
                accessorKey: "number",
                footer: totalSentTexts?.toString()
              },
              {
                header: "Percentage",
                dataType: "number" as CellDataType,
                accessorKey: "percentage"
              }
            ]}
            data={[
              ...(sentTextSummary
                ? sentTextSummary.map((v: TextStatusSummary) => ({
                    status: (
                      <Link
                        to={`/projects/${id}/${
                          v.status === "Test"
                            ? "test_texts"
                            : v.status === "Seed"
                            ? "seed_texts"
                            : "texts"
                        }`}
                        state={{ textType: v.status }}
                      >
                        {v.status}
                      </Link>
                    ),
                    number: v.number,
                    percentage: `${round((v.number / totalTexts) * 100, 2)}%`
                  }))
                : [])
            ]}
          />
        </Card.Flex>
      </Card.Flex>
    );

    // error text summary card
    const errorTextSummary =
      generalTextStatusSummary?.generalTextStatusSummary?.error;
    const infoCardTextErrorSummary = (
      <Card.Flex>
        <StyledHeading>Error Texts</StyledHeading>
        <Table
          variant="minimal"
          disableSort
          hideHeader
          columns={[
            {
              header: "Status",
              accessorKey: "status",
              footer: "Total"
            },
            {
              header: "Quantity",
              dataType: "number" as CellDataType,
              accessorKey: "number",
              footer: totalErrorTexts?.toString()
            },
            {
              header: "Percentage",
              dataType: "number" as CellDataType,
              accessorKey: "percentage"
            }
          ]}
          data={[
            ...(errorTextSummary
              ? errorTextSummary.map((v: TextStatusSummary) => ({
                  status: (
                    <Link
                      to={`/projects/${id}/texts`}
                      state={{ textType: v.status }}
                    >
                      {v.status}
                    </Link>
                  ),
                  number: v.number,
                  percentage: `${round((v.number / totalTexts) * 100, 2)}%`
                }))
              : [])
          ]}
        />
      </Card.Flex>
    );

    return [
      ...cards,
      infoCardPhones,
      infoCardRepeatedTexts,
      infoCardOverallTextSummary,
      infoCardTextReadySendSummary,
      infoCardTextSentSummary,
      infoCardTextErrorSummary,
      infoCardTextPerProvider
    ];
  }, [
    project,
    generalTextStatusSummary,
    duplicatePhonesSummary,
    optOutPhonesSummary,
    invalidPhonesSummary,
    totalTextsSummary,
    textsPerProviderSummary,
    id,
    handleRect,
    mediaHeight
  ]);

  // stages
  const stages = useMemo(() => {
    return [
      ...projectStatusGroupOptions.map(psgo => {
        const subStages = projectStatusOptions
          .filter(pso => pso.stage === `ProjectStatus::::${psgo.label}`)
          .map(pso => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { color, ...rest } = pso;
            return rest;
          });
        return {
          ...psgo,
          subStages
        };
      })
    ];
  }, [projectStatusOptions]);

  // menuanchor props
  const menuAnchorProps: any = useMemo(() => {
    const status = projectStatus || ProjectStatus.Approved;
    const anchorProps: any = {
      editLinks: sections,
      cards,
      kebabMenuItems,
      name: project?.name,
      statusOptions: projectStatusOptions,
      currentStatus: status,
      resourceName: "Project",
      currentStage: project?.projectStatusGroup as string,
      currentSubStage: addSpacesBetweenWords(status),
      subItem: addSpacesBetweenWords(project?.projectType),
      stages,
      photoUrl: project?.client?.mediaUrl
        ? `${process.env.REACT_APP_BACKEND_BASE_END_POINT}${project.client.mediaUrl}`
        : "",
      onFavoriteToggle: () => toggleFavorite({ variables: { projectId: id } }),
      favorite: isFavorite,
      onEditPath: "basic-info",
      onViewPath: "overview",
      defaultIcon: "folder" as IconNames
    };
    if (project?.projectStatusGroup === ProjectStatusGroup.Error.toString()) {
      anchorProps.stagesCompleted = true;
      anchorProps.stageColors = {
        previous: "wine50",
        previousLine: "wine80",
        current: "wine50",
        next: "wine50"
      };
    }
    return anchorProps;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    kebabMenuItems,
    cards,
    stages,
    projectStatus,
    isFavorite,
    toggleFavorite
  ]);

  const onSend = useMemo(() => {
    return (phoneTo: string[], body: string, fromPhoneId: string) => {
      loadSendProject(
        LoadSendProjectOption.TestText,
        phoneTo,
        body,
        fromPhoneId
      );
      toggleShowTestTexts(false);
    };
  }, [loadSendProject]);

  const managerPhones = useMemo(() => {
    return project?.manager?.recipientPhones || [];
  }, [project]);
  const clientPhones = useMemo(() => {
    return project?.client?.clientRecipientPhones || [];
  }, [project]);
  const fromPhones = useMemo(() => {
    const allPhones = project?.projectPhones?.map(p => {
      return {
        ...p.phone,
        mediaPosition: p.campaign.brand.mediaPosition
      };
    });
    return Array.from(new Set(allPhones));
  }, [project]);

  // if resources are still loading
  if (projectLoading || statusOptionsloading || !projectStatusOptions) {
    return <MenuAnchor skeleton />;
  } else {
    return (
      <>
        <BaseResource.Anchor {...menuAnchorProps} />
        <ConfirmationModal
          message="Are you sure you want to delete this project?"
          title="Delete Project?"
          confirmLabel="Delete"
          confirmIcon="trash2"
          open={showModal}
          handleClose={handleClose}
          onConfirm={deleteProject}
        />
        {showTestTexts && (
          <>
            <ProjectTestTexts
              projectName={project?.name || ""}
              message={project?.message || ""}
              projectMediaUrl={project?.mediaUrl}
              projectMediaPosition={project?.mediaPosition}
              managerPhones={managerPhones}
              clientPhones={clientPhones}
              fromPhones={fromPhones}
              onSend={onSend}
              onClose={() => {
                toggleShowTestTexts(false);
              }}
              clientId={projectData?.project?.client.id}
            />
          </>
        )}
      </>
    );
  }
};

export default ProjectAnchor;
