import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import _ from "lodash";
import {
  ADD,
  BaseResource,
  FormBuilderStepperFormProps,
  FormBuilderStepperMenuProps,
  FormBuilderStepperServerResponse,
  useDispatchGrowlContext,
  useCurrentUserContext
} from "@onehq/framework";
import {
  DotColor,
  FormMethods,
  StepperItemIndex,
  SubmitValues
} from "@onehq/anton";
import {
  addSpacesBetweenWords,
  getOptionFieldsAndColor,
  showErrorMessage
} from "../../utils";
import {
  ProjectFieldsFragment,
  ProjectStatus,
  ProjectStatusGroup,
  ProjectStep,
  useCreateProjectMutation,
  useGetProjectLazyQuery,
  useGetProjectStatusOptionsQuery,
  useUpdateProjectMutation
} from "../../generated/graphql";
import ProjectFormFields from "../projects/sections/ProjectFormFields";
import SendingScheduleForm from "../projects/sections/SendingScheduleForm";
import { PROJECTS_PATH } from "../../constants";
import ListLoader from "./listLoader";
import ListSummary from "./listSummary";
import ProjectTexterForm from "../projects/sections/ProjectTexterForm";
import { normalizeProject, projectInitialValues } from "../projects";
import ProjectCampaignsForm from "../projects/sections/ProjectCampaignsForm";
import { stepIndexToName, stepNameToIndex } from "../../utils/stepperSteps";
import ProjectTestTextStep from "./ProjectTestTextStep";

const requiredFields = ["name", "projectType", "clientId", "managerId"];

const COLORS: DotColor[] = [
  "wine50",
  "mint60",
  "royal50",
  "pea60",
  "gold60",
  "gold80",
  "iris50",
  "iris80"
];

const projectStatusGroupOptions = _.orderBy(
  Object.keys(ProjectStatusGroup),
  undefined,
  "desc"
).map((item, key) => ({ id: key, label: item }));

const defaultStep = { index: 0, subIndex: -1, pageIndex: -1 };
const defaultMsg = "STOP=END";

const Stepper = () => {
  const [project, setProject] = useState<ProjectFieldsFragment>();
  const [activeStep, setActiveStep] = useState<StepperItemIndex>(defaultStep);
  const { id } = useParams();

  const [createProject] = useCreateProjectMutation();
  const [updateProject] = useUpdateProjectMutation();
  const [updateProjectStep] = useUpdateProjectMutation();

  const [getProjectQuery] = useGetProjectLazyQuery();
  const { data: statusOptionsData } = useGetProjectStatusOptionsQuery();
  const currentUser = useCurrentUserContext();

  useEffect(() => {
    if (id && !project) {
      getProjectQuery({ variables: { id } })
        .then(response => {
          const project = response.data?.project || undefined;
          setProject(project);

          const step = project?.currentStep || ProjectStep.BasicInfo;
          setActiveStep(stepNameToIndex("Project", step));
        })
        .catch(error => console.log(error));
    }
  }, [id, project, getProjectQuery]);

  const alert = useDispatchGrowlContext();
  const navigateTo = useNavigate();

  const onSubmit = async (data: SubmitValues<any>, m: FormMethods<any>) => {
    if (requiredFields.some(f => !Object.keys(data as {}).includes(f))) {
      return;
    }

    const serverResponse: FormBuilderStepperServerResponse = {
      type: data.id ? "update" : "create",
      errors: {}
    };

    const { setError } = m;

    data.message = data.message || defaultMsg;

    const attributes = normalizeProject(data);

    if (attributes._omitSubmit) {
      serverResponse.errors[""] = "";
      return serverResponse;
    }

    if (!data.id) {
      await createProject({
        variables: { attributes },
        onCompleted: result => {
          const errors = result.createProject?.errors || {};
          const project = result.createProject?.resource || undefined;
          if (Object.keys(errors).length > 0) {
            showErrorMessage(errors, setError);
            errorAlert(errors);
          } else if (project) {
            setProject(project);
            successAlert("Project", "created");
            navigateTo(project.id);
          }
          serverResponse.errors = errors;
        }
      });
    } else {
      const id = data.id;
      await updateProject({
        variables: { id, attributes },
        onCompleted: result => {
          const errors = result.updateProject?.errors || {};
          if (Object.keys(errors).length > 0) {
            showErrorMessage(errors, setError);
          } else {
            setProject(result.updateProject?.resource || undefined);
          }
          serverResponse.errors = errors;
        }
      });
    }

    return serverResponse;
  };

  const statusOptions = useMemo(() => {
    return getOptionFieldsAndColor(statusOptionsData, COLORS);
  }, [statusOptionsData]);

  const stages = useMemo(() => {
    return [
      ...projectStatusGroupOptions.map(psgo => {
        const subStages = statusOptions
          .filter(pso => pso.stage === `ProjectStatus::::${psgo.label}`)
          .map(pso => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { color, ...rest } = pso;
            return rest;
          });
        return {
          ...psgo,
          subStages
        };
      })
    ];
  }, [statusOptions]);

  const onFinish = useMemo(() => {
    return () => {
      if (project?.id) navigateTo(`/${PROJECTS_PATH}/${project?.id}`);
    };
  }, [project, navigateTo]);

  const onStepClick = useMemo(() => {
    return (step: StepperItemIndex) => {
      const newStep = { ...step };

      setActiveStep(newStep);

      if (id) {
        const stepName = stepIndexToName("Project", newStep);
        const finalName = addSpacesBetweenWords(stepName);
        void updateProjectStep({
          variables: {
            id,
            attributes: { currentStepId: `ProjectStep::::${finalName}` }
          }
        });
      }
    };
  }, [id, updateProjectStep]);

  // just a generic wrapper of a repetitive error alert, for short
  const errorAlert = (errors: {}) => {
    const messages = Object.entries(errors).map(([key, value]) => {
      return `${key} ${value}.`;
    });
    alert({
      type: ADD,
      payload: {
        title: "Error",
        message: messages.join(" "),
        variant: "error"
      }
    });
  };

  // just a generic wrapper of a repetitive success alert, for short
  const successAlert = (resource: string, action: "created" | "edited") => {
    alert({
      type: ADD,
      payload: {
        title: "All changes saved",
        message: `The ${resource} has been ${action} successfully`,
        variant: "success"
      }
    });
  };

  const initialValues = useMemo(() => {
    const values = projectInitialValues(project as ProjectFieldsFragment);
    Object.entries(values).forEach(([key, value]) => {
      if (value === undefined) delete values[key];
    });
    const msg = project?.message === defaultMsg ? undefined : project?.message;
    const managerId = project?.manager.id
      ? values.managerId
      : {
          label: currentUser?.data?.name || "",
          value: currentUser?.data?.id || ""
        };
    return { ...project, ...values, message: msg, managerId };
  }, [project, currentUser]);

  const sections = useMemo(() => {
    return [
      {
        id: "basic-info",
        name: "Basic Info",
        render: () => <ProjectFormFields showOnlyBasicInfo hideLegend />
      },
      {
        id: "list-import",
        name: "List Import",
        subSections: [
          {
            id: "list-loader",
            name: "Setup",
            render: () => <ListLoader />
          },
          {
            id: "list-summary",
            name: "Summary",
            render: () => <ListSummary />
          }
        ]
      },
      {
        id: "project-campaigns",
        name: "Sender",
        render: () => <ProjectCampaignsForm />
      },
      {
        id: "sending-schedule",
        name: "Sending Schedule",
        render: () => <SendingScheduleForm hideLegend />
      },
      {
        id: "texters",
        name: "Texters",
        render: () => <ProjectTexterForm />
      },
      {
        id: "test-text",
        name: "Test Text",
        render: () => (
          <ProjectTestTextStep
            project={project}
            message={project?.message || ""}
            projectMediaUrl={project?.mediaUrl}
            projectMediaPosition={project?.mediaPosition}
            clientId={project?.client.id}
          />
        )
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const formProps: FormBuilderStepperFormProps = useMemo(() => {
    return {
      sections,
      onSubmit,
      values: initialValues,
      autosave: true
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections, initialValues]);
  const menuProps: FormBuilderStepperMenuProps = useMemo(() => {
    return {
      name: project?.name,
      resourceName: "Project",
      statusOptions,
      currentStatus: project?.projectStatus || ProjectStatus.Submitted,
      currentStage: project?.projectStatusGroup as string,
      currentSubStage: addSpacesBetweenWords(project?.projectStatus),
      subItem: addSpacesBetweenWords(project?.projectType),
      stages,
      photoUrl: project?.client?.mediaUrl
        ? `${process.env.REACT_APP_BACKEND_BASE_END_POINT}${project.client.mediaUrl}`
        : "",
      activeStep,
      onStepClick,
      onFinish,
      initialStatus: id ? "default" : "blocked",
      stepperType: "custom",
      grouped: false
    };
  }, [project, statusOptions, stages, activeStep, id, onStepClick, onFinish]);

  return (
    <BaseResource.FormBuilderStepper
      formProps={formProps}
      menuProps={menuProps}
    />
  );
};

export default Stepper;
